import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import Dropdown from './Dropdown'
import SlideMenu from './SlideMenu'
import site_logo from '../../static/site_logo_7.svg'

export default function Header() {
  return (
    <header className="site-header">
      <SlideMenu>
        <NavItems />
      </SlideMenu>

      <Link to="/" className="site-header__title">
        <img src={site_logo} alt="site_logo" className="site-header__logo" />
      </Link>

      <div className="site-header__navigation--nav">
        <NavItems />
      </div>
    </header>
  )
}

function NavItems() {
  return (
    <Fragment>
      <Link to="/women" className="site-header__link" activeClassName="active">
        womens
      </Link>
      <Link
        to="/accessories"
        className="site-header__link"
        activeClassName="active"
      >
        accessories
      </Link>
      <Dropdown name="past projects" main="/concept">
        <Link
          to="/concept"
          className="site-header__link"
          activeClassName="active"
        >
          concept
        </Link>

        <Link
          to="/technical-development"
          className="site-header__link"
          activeClassName="active"
        >
          technical development
        </Link>
        <Link
          to="/technical-design"
          className="site-header__link"
          activeClassName="active"
        >
          technical design
        </Link>
      </Dropdown>
    </Fragment>
  )
}
