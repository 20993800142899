import React, { Component } from 'react'
import { Link } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default class Sidebar extends Component {
  componentDidUpdate() {
    if (this.props.isOpen) this.slider.focus()
  }

  render() {
    return (
      <div
        tabIndex="-1"
        ref={(slider) => (this.slider = slider)}
        className={`sidebar ${
          this.props.isOpen ? '' : 'hidden sidebar--hidden'
        }`}
        onKeyUp={(event) => {
          if (event.keyCode === 27) this.props.toggleSidebar()
        }}
        onClick={(event) => {
          if (event.target.tagName === 'A') this.props.toggleSidebar()
        }}
        aria-hidden={!this.props.isOpen}
      >
        <button className="sidebar__close" onClick={this.props.toggleSidebar}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <div className="f f-col">{this.props.children}</div>

        <div className="sidebar__footer f f-col">
          <Link className="sidebar__footer_link" to="/cv">
            cv
          </Link>
          <Link className="sidebar__footer_link" to="/contact">
            contact
          </Link>
        </div>
      </div>
    )
  }
}
