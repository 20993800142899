import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

export default class Dropdown extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
  }

  state = {
    isActive: false,
  }

  setActive = () => this.setState({ isActive: true })
  setInactive = () => this.setState({ isActive: false })
  toggleActive = () =>
    this.state.isActive ? this.setInactive() : this.setActive()

  handleClick = () => {
    const isMobile = navigator.userAgent.match(/iPad|mobile|Android|Touch/i)

    // Toggle dropdown if mobile, otherwise navigate to main link
    isMobile ? this.toggleActive() : navigate(this.props.main)
  }

  render() {
    const { isActive } = this.state

    return (
      <div
        className="dropdown"
        onMouseEnter={this.setActive}
        onMouseLeave={this.setInactive}
        role="listbox"
        tabIndex="0"
      >
        <div onClick={this.handleClick} className="dropdown__name">
          {this.props.name}
        </div>

        <div className={`dropdown__children ${isActive ? 'active' : ''}`}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
