import React from 'react'
import { Link } from 'gatsby'

export default function Footer() {
  return (
    <div className="site-footer">
      <div className="site-footer__links">
        <Link className="site-footer__link" to="/contact">
          contact
        </Link>
        <Link className="site-footer__link" to="/cv">
          cv
        </Link>
      </div>
      <span className="site-footer__copyright">
        © {new Date().getFullYear()} WWEI STUDIO - ALL RIGHTS RESERVED{' '}
      </span>
      <span className="site-footer__webmaster">
        made with <Heart /> by{' '}
        <a href="https://antonhalim.com" rel="noopener noreferrer">
          Anton Halim
        </a>
      </span>
    </div>
  )
}

function Heart() {
  return (
    <span className="site-footer__animated-heart" role="img" aria-label="heart">
      ❤️
    </span>
  )
}
