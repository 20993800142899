import React, { useState, Fragment } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import Sidebar from './Sidebar'

export default function SlideMenu({ children }) {
  const [isOpen, setIsOpen] = useState()

  return (
    <Fragment>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="site-header__navigation--slide-menu"
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      <Sidebar isOpen={isOpen} toggleSidebar={() => setIsOpen(!isOpen)}>
        {children}
      </Sidebar>
      {isOpen ? <Overlay toggleSidebar={() => setIsOpen(!isOpen)} /> : null}
    </Fragment>
  )
}

function Overlay(props) {
  return <div className="sidebar__overlay" onClick={props.toggleSidebar} />
}
