import React from 'react'
import ReactHelmet from 'react-helmet'

import favicon from '../../static/favicon.png'

const Head = () => (
  <ReactHelmet title="Wei Wei Studio">
    <html lang="en" amp />
    <meta name="description" content="Wei Wei Fashion Portofolio" />
    <link rel="shortcut icon" href={favicon} />
  </ReactHelmet>
)

export default Head
