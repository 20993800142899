import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Loadable from '@loadable/component'

import Head from '../Head.jsx'
import Header from '../Header.jsx'
import Footer from '../Footer.jsx'

// Initialize required lib
import '../../lib/initializers/swiper'

// Add Style
import '../../styles/index.styl'

const AuthenticationLayover = Loadable(() => import('./AuthenticationLayover'))

const successMessage = 'Thanks! Your message has been succesfully submitted.'

export class Layout extends Component {
  shouldShowSuccess() {
    return (
      this.props.location &&
      this.props.location.hash
        .split('#')
        .filter((e) => e)
        .includes('submitSuccess')
    )
  }

  componentDidMount() {
    this.hideSuccessMessage()
  }

  hideSuccessMessage() {
    if (this.shouldShowSuccess()) {
      setTimeout(() => {
        this.successMessage &&
          this.successMessage.classList.add('fadeOutUp', 'hidden')
        navigate('/')
      }, 5000)
    }
  }

  render() {
    return (
      <AuthenticationLayover>
        <Head />

        {this.shouldShowSuccess() ? (
          <div
            className="toaster success fadeInDown"
            ref={(msg) => (this.successMessage = msg)}
          >
            {successMessage}
          </div>
        ) : null}

        <Header />

        <div className="site">
          {this.props.children}
          <Footer />
        </div>
      </AuthenticationLayover>
    )
  }
}

export default Layout
